import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Wrapper = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 33px;
  margin: 45px 0;

    ${media.tablet`
      gap: 18px;
      
      & > *:not(:last-child) {
        width: calc((100% - 18px) / 2);
      }
      
      & > *:last-child {
        width: 100%;
      }
    `}

    ${media.mobile`
      flex-direction: column;
      gap: 18px;
      
      & > * {
        width: 100% !important;
      }
    `}
`;

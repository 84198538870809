import { Heading, Wrapper, Tags } from './styles';

const Included = ({ included, serviceIncluded }) => {
  const tags = JSON.parse(included);

  return (
    <Wrapper>
      <Heading>{serviceIncluded}</Heading>
      <Tags>
        {tags.map((tag, index) => <li key={index}>{tag}</li>)}
      </Tags>
    </Wrapper>
  );
};

export { Included };

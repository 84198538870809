import { Qube } from 'components/Qube';
import { Wrapper } from './styles';

const Features = (props) => {
  const {
    servicePrice,
    priceFrom,
    serviceDuration,
    duration,
    serviceDoctor,
    doctor
  } = props;

  return (
    <Wrapper>
      <Qube title={priceFrom} info={servicePrice} />
      <Qube title={duration} info={serviceDuration} />
      <Qube title={serviceDoctor} doctor={doctor} />
    </Wrapper>
  );
};

export { Features };

import LinkArrow from 'assets/icons/icon-arrow-right.svg';
import { ServiceItemWrapper, ServiceItemIcon } from './styles';
import { Navigator } from '../Layout/Navigator';

const ServiceItem = ({ recordId, name, priceFrom, showPrice }) => {
  return (
    <ServiceItemWrapper as={Navigator} recordId={recordId} className={showPrice ? 'showPrice' : ''}>
      <span>{name}</span>
      <ServiceItemIcon as={LinkArrow} />
      {showPrice && <div>{`${priceFrom || 0} грн`}</div>}
    </ServiceItemWrapper>
  );
};

export { ServiceItem };

import styled from 'styled-components';
import { media } from 'styles/mixins';

export const ServiceItemWrapper = styled.div`
    &.showPrice {
        padding: 18px 27px 65px 27px;
    }
  padding: 18px 27px;
    position: relative;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;

    ${media.mobile`
          padding: 18px;
        `}
    
    span {
        font-size: 18px;
        line-height: 27px;
    }
    
    div {
        color: #6E707C;
        position: absolute;
        bottom: 18px;
        left: 27px;
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
    }
`;

export const ServiceItemIcon = styled.svg`
  min-width: 27px;
  width: 27px;
`;

import {
  QubeWrapper,
  QubeTitle,
  QubeInfo,
  QubeIcon,
  DoctorPreview,
  DoctorPreviewName,
  DoctorPreviewRole,
  DoctorPreviewInfo
} from './styles';
import CubeLinkIcon from '../../assets/icons/icon-arrow-right.svg';
import Image from '../Image';
import { Navigator } from '../Layout/Navigator';

const Qube = ({ title, info, doctor }) => {
  return (
    <QubeWrapper className={doctor ? 'is-doctor' : ''} as={doctor ? Navigator : null} recordId={doctor?.id}>
      <QubeTitle>{title}</QubeTitle>
      {doctor ? (
        <>
          <QubeIcon as={CubeLinkIcon} />
          <DoctorPreview>
            <Image image={doctor.photo} alt={doctor.fullName} />
            <DoctorPreviewInfo>
              <DoctorPreviewName>{doctor.fullName}</DoctorPreviewName>
              <DoctorPreviewRole>{doctor.role}</DoctorPreviewRole>
            </DoctorPreviewInfo>
          </DoctorPreview>
        </>
      ) : (
        <QubeInfo>{info}</QubeInfo>
      )}
    </QubeWrapper>
  );
};

export { Qube };

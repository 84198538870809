import styled from 'styled-components';
import { media } from 'styles/mixins';

export const QubeWrapper = styled.li`
  position: relative;
  border-radius: 20px;
  padding: 27px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% / 3 - 22px);
  min-height: 225px;
    
    ${media.mobile`
      min-height: 150px;
      
      &.is-doctor {
        gap: 40px;
      }
    `}
`;

export const QubeTitle = styled.span`
  font-size: 40px;
  line-height: 54px;
  font-weight: 400;
    margin-bottom: 18px;

    ${media.mobile`
      font-size: 27px;
      line-height: 36px;
      font-weight: 500;
    `}
`;

export const QubeInfo = styled.div`
  font-size: 15px;
  color: #A19EA5;
`;

export const QubeIcon = styled.svg`
  position: absolute;
  top: 36px;
  right: 18px;
    
    ${media.mobile`
      top: 25px;
    `}
`;

export const DoctorPreview = styled.div`
  display: flex;
  flex-direction: row;
  gap: 9px;
  align-items: center;
  
  .gatsby-image-wrapper {
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 4px;
  }
`;

export const DoctorPreviewInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

export const DoctorPreviewName = styled.span`
  display: block;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
`;

export const DoctorPreviewRole = styled.span`
  color: #6E707C;
  font-size: 15px;
  line-height: 18px;
    font-weight: 400;
`;

import styled from 'styled-components';
import { media } from '../../../../styles/mixins';

export const Wrapper = styled.div`
  background-color: #FDECF7;
  padding-top: 46px;
  padding-bottom: 46px;
  margin-top: 72px;

    ${media.mobile`
      margin-top: 54px;
      padding-top: 18px;
      padding-bottom: 18px;
    `}
`;

export const Heading = styled.h3`
  color: #030D41;
  font-size: 40px;
  margin-bottom: 36px;
  font-weight: 600;
  line-height: 54px;

    ${media.mobile`
      font-size: 27px;
      font-weight: 500;
      line-height: 36px;
      margin-bottom: 27px;
    `}
`;

export const RelatedList = styled.div`
  display: grid;
  grid-gap: 18px;
  grid-template-columns: repeat(3, 1fr);

    ${media.tablet`
      grid-template-columns: repeat(2, 1fr);
    `}
    
    ${media.mobile`
      grid-template-columns: repeat(1, 1fr);
    `}
`;

import styled from 'styled-components';
import { media } from '../../../../styles/mixins';

export const Wrapper = styled.div``;

export const Heading = styled.div`
  font-size: 27px;
  color: #030D41;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 27px;
`;

export const Tags = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 9px;
    padding: 6px 18px;
    border: 1px solid #F582C9;
    border-radius: 40px;

      ${media.mobile`
        border-radius: 20px;
      `}
  }
`;

import { graphql } from 'gatsby';
import { PageWrapper } from 'components/Layout/PageWrapper';
import { MainContainer } from 'components/UI/Containers';
import { Description } from './components/Description';
import { Included } from './components/Included';
import { RelatedServices } from './components/RelatedServices';
import { Hero } from './components/Hero';
import { Features } from './components/Features';

const ServicePageTemplate = ({ data, pageContext }) => {
  const {
    datoCmsSymmetryService: pageData,
    datoCmsSTranslation: translations,
  } = data;
  const {
    serviceDoctor,
    serviceDuration,
    serviceIncluded,
    serviceMoreCases,
    servicePrice,
    serviceRelated
  } = translations;
  const {
    seo,
    title,
    description,
    priceFrom,
    duration,
    doctor,
    included,
    relatedServices
  } = pageData;
  const { seoTitle, seoDescription, seoImage } = seo || {};

  return (
    <PageWrapper
      pageData={pageContext}
      {...{ seoTitle, seoDescription, seoImage }}
    >
      <MainContainer>
        <Hero {...{ title, serviceMoreCases }} />
        <Description {...{ description }} />
        <Features {...{ priceFrom, duration, doctor, servicePrice, serviceDuration, serviceDoctor }} />

        {included && (
          <Included {...{ included, serviceIncluded }} />
        )}
      </MainContainer>
      <RelatedServices {...{ relatedServices, serviceRelated }} />
    </PageWrapper>
  );
};

export default ServicePageTemplate;

export const query = graphql`
  query ServicePageQuery($locale: String!, $id: String!) {
    datoCmsSTranslation(locale: { eq: $locale }) {
      serviceDoctor
      serviceDuration
      serviceIncluded
      serviceMoreCases
      servicePrice
      serviceRelated
    }
    datoCmsSymmetryService(locale: { eq: $locale }, originalId: { eq: $id }) {
      locale
      title
      slug
      cases {
        id: originalId
        title
        link
        previewImage {
          gatsbyImageData
          url
          format
        }
      }
      description {
        value
        links
        blocks {
          __typename
          id: originalId
          before {
            gatsbyImageData
            url
            format
          }
          after {
            gatsbyImageData
            url
            format
          }
        }
      }
      priceFrom
      duration
      doctor {
        ...DatoCmsSymmetryDoctorFragment
      }
      included
      relatedServices {
        id: originalId
        title
      }
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }
  }
  
  fragment DatoCmsSymmetryDoctorFragment on DatoCmsSymmetryDoctor {
    id: originalId
    role
    fullName
    photo {
      gatsbyImageData
      format
      url
    }
  }
`;

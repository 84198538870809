import { MainContainer } from 'components/UI/Containers';
import { ServiceItem } from 'components/ServiceItem';
import { Wrapper, Heading, RelatedList } from './styles';

const RelatedServices = ({ serviceRelated, relatedServices }) => {
  return (
    <MainContainer as={Wrapper}>
      <Heading>{serviceRelated}</Heading>
      <RelatedList>
        {relatedServices?.map(({ title, id }) => (
          <ServiceItem key={id} recordId={id} name={title} />
        ))}
      </RelatedList>
    </MainContainer>
  );
};

export { RelatedServices };

import { HeroWrapper, Cases, Case } from './styles';

const Hero = ({ title }) => {
  return (
    <HeroWrapper>
      <h1>{title}</h1>
    </HeroWrapper>
  );
};

export { Hero };

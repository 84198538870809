import styled from 'styled-components';
import { RoundedContainer } from 'styles/elements';
import { media } from 'styles/mixins';

export const HeroWrapper = styled(RoundedContainer)`
  h1 {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    line-height: 54px;

      ${media.mobile`
        font-size: 27px;
        font-weight: 500;
        line-height: 36px;
      `}
  }
`;

export const Cases = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const Case = styled.li`
  display: flex;
`;
